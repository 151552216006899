






































import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			isOnDragOver: false,
			imageFileBase64: "" as string,
			imageFile: {} as File,
			email: "",
			name: "",
			message: ""
		};
	},
	created() {
		this.reload();
	},
	methods: {
		reload() {
			this.email = this.getUserInformation.email;
			this.name = this.getUserInformation.name;
			this.message = this.getUserInformation.message;
		},
		onImageChange(e: any) {
			this.imageFile = e.target.files[0];
		},
		onImageDrop(e: DragEvent) {
			e.preventDefault();

			let file = e.dataTransfer!.files[0];
			if (file) {
				this.imageFile = file;
			}
			this.isOnDragOver = false;
		},
		onImageDropOver(e: DragEvent) {
			this.isOnDragOver = true;
			e.preventDefault();
		},
		encodeBase64ImageFile(image: File): Promise<string> {
			return new Promise<string>((resolve, reject) => {
				if (!image) return resolve("");
				let reader = new FileReader();
				reader.readAsDataURL(image);
				reader.onload = (event: any) => {
					resolve(event.target.result);
				};
				reader.onerror = error => {
					reject(error);
				};
			});
		},
		save() {
			this.$store.commit("pushLoading", {
				name: "CHANGE_INFORMATION",
				message: "프로필 수정 중"
			});
			if (this.imageFileBase64) {
				this.$store.commit("pushLoading", {
					name: "SET_PROFILE_IMAGE",
					message: "동아리 사이트 수정 중"
				});
				this.$store
					.dispatch("SET_PROFILE_IMAGE", {
						img: this.imageFileBase64
					})
					.then(data => {
						console.log(data);
						this.$store.commit("clearLoading", "SET_PROFILE_IMAGE");
						this.$store
							.dispatch("CHANGE_INFORMATION", {
								name: this.name,
								message: this.message
							})
							.then(data => {
								this.$store.commit("clearLoading", "CHANGE_INFORMATION");
								this.$store.commit("showNotice", `프로필 수정 성공`);
							});
					})
					.catch(err => {});
			} else {
				this.$store
					.dispatch("CHANGE_INFORMATION", {
						name: this.name,
						message: this.message
					})
					.then(data => {
						this.$store.commit("clearLoading", "CHANGE_INFORMATION");
						this.$store.commit("showNotice", `프로필 수정 성공`);
					});
			}
		},
		changePassword() {
			this.$store.commit("pushLoading", {
				name: "SEND_CHANGEPASSWORD_EMAIL",
				message: "이메일 보내는 중"
			});
			this.$store
				.dispatch("SEND_CHANGEPASSWORD_EMAIL", { email: this.email })
				.then(data => {
					this.$store.commit("clearLoading", "SEND_CHANGEPASSWORD_EMAIL");
					this.$store.commit("showNotice", `${this.email} 메일함을 확인해주세요.`);
				})
				.catch(err => console.log(err));
		},
		delete() {
			// TODO: 계정삭제
		}
	},
	watch: {
		getToken() {
			this.reload();
		},
		imageFile() {
			this.encodeBase64ImageFile(this.imageFile).then((imagebase64: string) => {
				this.imageFileBase64 = imagebase64;
			});
		}
	},
	computed: {
		getProfileImage(): string {
			if (this.imageFileBase64) return this.imageFileBase64;
			else if (this.getUserInformation.imgPath) return this.$store.state.mainPath + this.getUserInformation.imgPath;
			else return "https://pbs.twimg.com/profile_images/770139154898382848/ndFg-IDH_400x400.jpg";
		},
		getUserInformation(): any {
			return this.$store.state.userInformation;
		},
		getToken() {
			return this.$store.state.userToken;
		}
	}
});
